import "../sass/main.scss";
import 'aos/dist/aos.css';
import '../scripts/fancybox/jquery.fancybox.min.css';
import AOS from "aos";
//import $ from "jquery";
//import "slick-carousel";
import Cookie from "js-cookie";

const $fancybox = window.$.fancybox;

// IMPORTANT NOTE: You have to crowbar this code into the existing main.js file in the LIVE dist folder.
// DO NOT BUILD IT!!! It will inevitably mess up the live site :-)
// DO NOT BUILD IT!!! It will inevitably mess up the live site :-)
// DO NOT BUILD IT!!! It will inevitably mess up the live site :-)

var slickInterval = window.setInterval(slickThings, 300);

// TIP: Look for $(document).ready on live dist/main.js...

$(document).ready(function ()
{
    $('.slider').on('init', function (event)
    {

        setTimeout(function ()
        {
            var list = $(".slider").find(".slick-list").find('.slick-track').children();

            list.each(function ()
            {

                var img = $(this).find('img');

                var imgParent = img.parent();

                $(".slick-cloned [data-mobile]").attr("id", "mobile-image");
                $(".slick-cloned [data-desktop]").attr("id", "desktop-image");

                //console.log($(window).width());
                if ($(window).width() <= 420)
                {
                    var mobileImage = imgParent.find('#mobile-image')

                    imgParent.css('background-image', "url('" + mobileImage.data('mobile') + "'");
                    img.attr('src', mobileImage.data('mobile'))
                } else
                {
                    var mobileImage = imgParent.find('#desktop-image')
                    imgParent.css('background-image', "url('" + mobileImage.data('desktop') + "'");
                    img.attr('src', mobileImage.data('desktop'))
                }


            });
        }, 2000);
    });
    $('#promo1').click(function ()
    {
        $fancybox({
            href: '/popup-promo.aspx?id=1',
            type: 'ajax',
            width: '100%',
            height: '300px',
            maxWidth: 800,
            maxHeight: 560,
            closeBtn: true,
            autoCenter: true
        });
        return false;
    });
    $('#promo2').click(function ()
    {
        $fancybox({
            href: '/popup-promo.aspx?id=2',
            type: 'ajax',
            width: '100%',
            height: '300px',
            maxWidth: 800,
            maxHeight: 560,
            closeBtn: true,
            autoCenter: true
        });
        return false;
    });
    $('#promo3').click(function ()
    {
        $fancybox({
            href: '/popup-promo.aspx?id=3',
            type: 'ajax',
            width: '100%',
            height: '300px',
            maxWidth: 800,
            maxHeight: 560,
            closeBtn: true,
            autoCenter: true
        });
        return false;
    });
    $(".fb-iframe").click(function ()
    {
        $fancybox({
            href: $(this).attr("href"),
            type: 'iframe',
            padding: 5,
            width: '85%',
            height: '85%',
            maxWidth: 900,
            maxHeight: 500,
            autoCenter: true,
            fitToView: true,
            preload: true
        });
        return false;
    });
    setupExpandableClicks();
    mobileNavSetup();
    setupFooterQuickLinks();
    setupAdBar();
    setupPopup();
    if ($(".js-tab-section").length)
    {
        setupTabSection();
    }
    AOS.init({
        once: true,
    });
});

function slickThings()
{
    if (typeof $.fn.slick == 'function')
    {
        $(".slider").slick({
            prevArrow: "<div class='slider__prev'></div>",
            nextArrow: "<div class='slider__next'></div>",
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnHover: false
        });
        $(".slider-usp").slick({
            prevArrow: "<div class='slider__prev'></div>",
            nextArrow: "<div class='slider__next'></div>",
            mobileFirst: true,
            autoplay: true,
            autoplaySpeed: 2500,
            pauseOnHover: false,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            }, {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    dots: false,
                    arrows: true
                }
            }]
        });
        $(".js-slider-three-rows").slick({
            prevArrow: "<div class='slider__prev'></div>",
            nextArrow: "<div class='slider__next'></div>",
            autoplay: true,
            autoplaySpeed: 6000,
            rows: 3,
            arrows: false,
            dots: true,
            pauseOnHover: false,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    rows: 1,
                    dots: false,
                    arrows: false
                }
            }]
        });
        $(".js-slider-three-columns").slick({
            prevArrow: "<div class='slider__prev'></div>",
            nextArrow: "<div class='slider__next'></div>",
            autoplay: true,
            autoplaySpeed: 3000,
            slidesToShow: 3,
            dots: true,
            arrows: false,
            pauseOnHover: false,
            responsive: [{
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                    arrows: false
                }
            }]
        });
        window.clearInterval(slickInterval)
    }
}

function setupTabSection()
{
    $('.js-tab-buttons a').click(function ()
    {
        $('.js-tab-buttons a').removeClass('active');
        $(this).addClass('active');
        var itm = $(this).attr('data-show');
        if (!$(itm).hasClass('js-tab-show'))
        {
            $('.js-tab').each(function ()
            {
                if ($(this).hasClass('js-tab-show'))
                {
                    $(this).removeClass('js-tab-show').hide();
                }
            });
            $(itm).fadeIn().addClass('js-tab-show');
            if ($('.js-show-directions a').hasClass('active'))
            {
                $('.js-show-directions a').click();
            }
        }
    });
    $('.js-show-directions a').click(function ()
    {
        $('#js-map-directions').toggle();
        $(this).toggleClass('active');
    })
}

function setupAdBar()
{
    $(document).scroll(function ()
    {
        if (window.location.href.indexOf('get-a-') === -1)
        {
            var y = $(this).scrollTop();

            if ($(window).width() <= 768)
            {

                if (y > 700)
                {
                    $('.quote-footer-bar').addClass('is-active');
                } else
                {
                    $('.quote-footer-bar').removeClass('is-active');
                };

            } else
            {
                if (y > 600)
                {
                    $('.quote-footer-bar').addClass('is-active');
                } else
                {
                    $('.quote-footer-bar').removeClass('is-active');
                };
            }
        }
    });
}

function setupExpandableClicks()
{
    $(".expandable").click(function ()
    {
        $(this).toggleClass("expandable--open");
    })
}

function mobileNavSetup()
{
    $(".js-toggle-mobile-nav").click(function ()
    {
        $(".js-mobile-nav").toggleClass("is-active");
        $("html, body").toggleClass("overflow-hidden");
    });
    $('.mobile-nav__sub-nav').hide();
    var itm = $(".mob-menu-wrapper:eq(3)")
    $(itm).parent().prepend(itm);

    $(".js-mobile-expand").on("click", function ()
    {
        $(this).parent().next().toggle();
    });

    $(".js-mobile-expand").first().click();
}

function setupFooterQuickLinks()
{
    $("#quicklinks").click(function ()
    {
        $("#footerLinks").toggle();
        $(this).toggleClass('quicklinks-close');
    });
}

function setupPopup()
{

    var popupId = $(".quote-popup").attr("data-id");
    var popupQty = parseInt($(".quote-popup").attr("data-quantity"));

    if (!Cookie.get('MakeSpacePopUpCount' + popupId))
    {
        Cookie.set('MakeSpacePopUpCount' + popupId, 0, { expires: 5, path: '/' });
    }

    var numberOfTimesOpen = parseInt('0' + Cookie.get('MakeSpacePopUpCount' + popupId));
    var maxTimesToShow = popupQty;

    if (numberOfTimesOpen > maxTimesToShow)
    {

    }

    $('.sidetab').removeClass("sidetab--hidden").click(function ()
    {
        $fancybox({
            href: "#quote-popup",
            type: "inline",
            height: '300px',
            width: '800px',
            maxWidth: 1000,
            maxHeight: 560,
            opts: {
                smallBtn: false,
                afterClose: function ()
                {
                    check = true;
                    numberOfTimesOpen++;
                    Cookie.set('MakeSpacePopUpCount' + popupId, numberOfTimesOpen, { expires: 5, path: '/' });
                }
            }
        });
    })
}



